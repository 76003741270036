import gsap from 'gsap'

const PageLeave = (page, done) => {
  const tl = gsap.timeline({ paused: true })

  tl
    .set(document.body, { pointerEvents: 'none' })
    .to(page, {
      duration: 0.5,
      autoAlpha: 0
    })
    .add(() => { done() })

  return tl
}

export default PageLeave

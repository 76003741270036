<template>
  <div :class="$style.skills">
    <Column :large="true">
      <ul>
        <li :class="$style.listItem">
          <IconSkills color="violet" />
          <h5>Product Thinking</h5>
          <p>Focused on product and experience design passionate about interaction design and web development.</p>
        </li>
        <li :class="$style.listItem">
          <IconSkills color="orange" />
          <h5>Experience Design</h5>
          <p>Focused on product and experience design passionate about interaction design and web development.</p>
        </li>
        <li :class="$style.listItem">
          <IconSkills color="green" />
          <h5>Visual and interaction Design</h5>
          <p>Focused on product and experience design passionate about interaction design and web development.</p>
        </li>
      </ul>
    </Column>
  </div>
</template>

<script>
import Column from '@/components/Column'
import IconSkills from '@/components/IconSkills'

export default {
  components: {
    Column,
    IconSkills
  }
}
</script>

<style lang="scss" module>
.skills {
  overflow: hidden;
  ul {
    position: relative;
    padding: 10rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -50px;
      right: 0;
      bottom: 0;
      width: 100vw;
      display: block;
      background: #000;
      z-index: -1;
    }
  }
}

.listItem {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;

  h3 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  p {
    color: rgba($color-light, 0.5);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}
</style>

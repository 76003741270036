import gsap, { Expo } from 'gsap'

const BlackLeave = (page, done) => {
  const tl = gsap.timeline({ paused: true })

  tl
    .set(document.body, { pointerEvents: 'none' })
    .fromTo('#black-layer', {
      duration: 1.3,
      yPercent: 100,
      display: 'block'
    }, {
      yPercent: 0,
      ease: Expo.easeInOut
    }, 'start')
    .add(() => { done() })

  return tl
}

export default BlackLeave

import Vue from 'vue'
import PrismicVue from '@prismicio/vue'

import { gsap, Expo } from 'gsap'
import { getDevice, isMobile, isTouch, addClass } from './scripts/utils'
import EventBus from './scripts/event-bus'
import Scrollbar from './scrollbar'
import App from './App.vue'
import router from './router'
import store from './store/index'


// import './registerServiceWorker'

Vue.use(Scrollbar)
const accessToken = ''
const endpoint = 'https://stefano-derosa-website.prismic.io/api/v2'

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  // linkResolver
})


const hover = () => {
  EventBus.$emit('hover')
}

Vue.mixin({
  methods: {
    getDevice,
    isMobile,
    hover,
    checkCaption(status) {
      const caption = document.querySelector('#caption-child')
      const line = document.querySelector('.c-line')

      if (status.offset.y > 400 && status.offset.y < (status.limit.y - 400)) {
        if (caption.hasAttribute('data-hidden')) return

        caption.setAttribute('data-hidden', '')
        gsap.fromTo(caption, {
          duration: 0.6,
          autoAlpha: 0,
          x: -40
        }, {
          autoAlpha: 1,
          x: 0,
          ease: Expo.easeInOut
        })

        gsap.fromTo(line, {
          duration:0.6,
          autoAlpha: 1
        }, {
          autoAlpha: 0
        })
      } else {
        if (!caption.hasAttribute('data-hidden')) return

        caption.removeAttribute('data-hidden')
        gsap.to(caption, {
          duration: 0.6,
          autoAlpha: 0,
          x: -40,
          ease: Expo.easeInOut
        })

        gsap.to(line, {
          duration:0.6,
          autoAlpha: 1
        })
      }
    }
  }
})

if (!isTouch()) {
  addClass(document.documentElement, 'no-touchevents')
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <article >
    <Column>
      <div :class="$style.container">
        <div :class="$style.content">
          <h3 :class="$style.title">{{ slice.primary.title1 }}</h3>
          <div :class="$style.text">
            <prismic-rich-text :field="slice.primary.text"/>
          </div>
        </div>
        <div :class="$style.persona">
          <prismic-image :class="$style.image" :field="slice.primary['persona-image']"/>
          <div>
            <strong>{{ slice.primary.persona }}</strong>
            <span>{{ slice.primary['persona-role'] }}</span>
          </div>
        </div>
      </div>
    </Column>
  </article>
</template>

<script>
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  }
}
</script>

<style module lang="scss">
@import "~sass-mq/mq";

.container {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  font-size: font-size(l);
  margin: 0 auto;

  @include mq(sm) {
    width: 60%;
  }
}

.content {
  margin-bottom: 50px;
}

.title {
  margin-bottom: 20px;
  font-weight: font-weight(regular);
  font-size: font-size(l);
}

.text {
  margin-bottom: 20px;
}

.persona {
  display: flex;
  align-items: center;
  font-size: font-size(s);

  strong {
    font-weight: 400;
    opacity: 1;
  }

  span {
    opacity: 0.8;
  }

  > div {
    display: flex;
    flex-flow: column;
  }
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
</style>

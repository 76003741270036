import gsap, { Power2, Expo } from 'gsap'
import SplitText from 'gsap/SplitText'

const animations = new Map()
const lerp = (a, b, n) => ((1 - n) * a) + (n * b)

animations.set('text', (el, speed = 1.2, stagger = 0.013) => {
  if (!el) { return () => {} }

  const tl = gsap.timeline()
  const split = new SplitText(el)

  tl
    .fromTo(split.chars, speed, {
      autoAlpha: 0,
      yPercent: 30
    }, {
      autoAlpha: 1,
      yPercent: 0,
      stagger,
      ease: Expo.easeInOut
    })
    .add(() => {
      split.revert()
    })

  return tl
})

animations.set('fade', (el) => {
  return gsap.from(el, {
    duration: 1.2,
    autoAlpha: 0,
    ease: Power2.easeOut,
    clearProps: 'all'
  })
})

animations.set('fade-up', (el) => {
  return gsap.from(el, {
    duration: 1.2,
    autoAlpha: 0,
    yPercent: 20,
    ease: Power2.easeOut,
    clearProps: 'all'
  })
})

animations.set('scale-up', (el) => {
  return gsap.from(el, {
    duration: 1.2,
    autoAlpha: 0,
    scale: 1.2,
    ease: Expo.easeOut,
    clearProps: 'all'
  })
})

animations.set('stagger', (el, delay = 2) => {
  const tl = gsap.timeline({ delay })
  const split = new SplitText(el, { type: 'lines, words', linesClass: 'lines' })

  tl
    .from(split.lines, {
      yPercent: 30,
      autoAlpha: 0,
      stagger: 0.05,
      duration: 0.7,
      ease: Power2.easeOut
    })

  return tl
})


animations.set('reveal', (el) => {
  const initClipPath = 'inset(0.01% 100% 0.03% 0%)'
  const tl = gsap.timeline()

  tl
    .fromTo(el, {
      clipPath: initClipPath,
      webkitClipPath: initClipPath
    }, {
      duration: 1.4,
      clipPath: 'inset(0.01% 0.02% 0.03% 0%)',
      webkitClipPath: 'inset(0.01% 0.02% 0.03% 0%)',
      ease: Expo.easeInOut,
      clearProps: 'transform'
    })

  return tl
})

const offsets = []
animations.set('parallax', (el, i) => {
  const bound = el.parentElement.getBoundingClientRect().top
  let final = 1

  if (!el.getAttribute('data-animated')) {
    el.setAttribute('data-animated', true)
    offsets[i] = bound
  }

  const parallaxAttr = el.getAttribute('data-parallax')
  const elH = el.parentElement.clientHeight
  const relativity = parallaxAttr === 'relative' ? offsets[i] : ((window.innerHeight - elH) / 2)
  const offsetTop = bound - relativity
  const coefficent = parallaxAttr !== null && parallaxAttr !== 'relative' ? parallaxAttr : 0.2
  const maxY = Math.max(-window.innerHeight, offsetTop)
  const baseTransform = Math.floor(Math.min(window.innerHeight, maxY), 2)
  const translateY = baseTransform * parseFloat(coefficent)

  final = lerp(final, translateY, 1)
  gsap.set(el, { y: final })
})

export default animations

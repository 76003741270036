<template>
  <div :class="$style.hero">
    <Column>
      <div :class="[
        $style.image,
        visible ? $style.visible : null,
        $style[`background-${data['cover-size']}`]
      ]">
        <img data-hero-image data-animated :data-cover="id" :src="data.local_url_cover || data.cover.url" :alt="data.cover.alt">
      </div>
    </Column>
  </div>
</template>
<script>
import Column from '@/components/Column.vue'
import Engine from '@/scripts/webgl'

export default {
  props: ['data', 'id', 'visible'],
  components: {
    Column
  },
}
</script>

<style lang="scss" module>
.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.content {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  z-index: 10;
  position: relative;

  h1 {
    font-size: font-size(40);
    font-weight: font-weight(regular);
  }

  strong {
    font-weight: font-weight(regular);
    font-size: font-size(s);
    font-weight: font-weight(regular);
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  img {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    object-fit: cover;
  }
}
</style>

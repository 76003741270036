<template>
<transition appear @enter="onEnter">
  <div :class="[
      $style.cafonata,
      $route.name === 'project' && !isThemeLight ? $style.white : null,
      isMenuOpen ? $style.white : null,
    ]">
    <svg data-badge viewBox="0 0 200 200">
      <path id="circle" d="M20,100a80,80 0 1,0 160,0a80,80 0 1,0 -160,0"/>
      <text width="200">
        <textPath alignment-baseline="top" xlink:href="#circle">
          Milan – London {{ new Date().toLocaleDateString("en-EN", { weekday: 'long', month: 'long', day: 'numeric' }) }}
        </textPath>
      </text>
    </svg>
  </div>
</transition>
</template>

<script>
import gsap from 'gsap'

export default {
  props: ['isMenuOpen'],

  mounted() {
    gsap.to('[data-badge]', {
      duration:10,
      rotation: 360,
      repeat: -1,
      ease: 'linear.easeNone',
    })
  },

  computed: {
    isThemeLight() {
      return this.$store.state.isFooterLight
    }
  },

  methods: {
    onEnter(el, done) {
      gsap.from(el, {
        duration: 1,
        autoAlpha: 0,
        delay: 5,
        onComplete: () => {
          done()
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";

.cafonata {
  position: fixed;
  bottom: 0px;
  margin: $small-margin;
  right: 0;
  pointer-events: none;
  z-index: 25;
  width: 120px;
  height: 120px;

  @include mq(sm) {
    width: 230px;
    height: 230px;
  }

  &.dark {
    text {
      fill: $color-dark;
    }
  }

  &.white {
    text {
      fill: $color-main;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: transparent;
  }

  text {
    fill: $color-main;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6px;
    text-transform:uppercase;
    transition: all 0.3s ease-out;
  }

  &[data-green]  {
    text {
      fill: $color-main;
    }
  }
}
</style>

<template>
  <transition appear @enter="onEnterLoader" @leave="onLeaveLoader">
    <div :class="$style.wrapper">
      <!-- <span ref="bigNumber" :class="$style.bigNumber">{{this.realPercent}}%</span> -->
      <span ref="title" :class="$style.title">
        <span :class="$style"> Hello 👋 Wait a moment...</span>
      </span>
      <div ref="loader" :class="$style.loader">
        <span>{{realPercent}}</span>
        <div :class="$style.loading">
          <div :style="`transform: scale(${percent}, 1)`" :class="$style.bar"></div>
        </div>
        <span >100</span>
      </div>
    </div>
  </transition>
</template>

<script>
import gsap, { Expo } from 'gsap'
import SplitText from 'gsap/SplitText'

export default {
  props: ['percent', 'fetchData'],

  computed: {
    realPercent() {
      return Math.floor(this.percent * 100)
    }
  },

  methods: {
    onEnterLoader(el, done) {
      const tl = gsap.timeline({
        onComplete: () => {
          this.fetchData()
          done()
        }
      })

      const split = new SplitText(this.$refs.title)

      tl
        .add('start')
        .fromTo(split.chars, {
          autoAlpha: 0,
          yPercent: 30
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1.2,
          stagger: 0.013,
          ease: Expo.easeInOut
        }, 'start')
        .add(() => {
          split.revert()
        })
        .from(this.$refs.loader, {
          ease: Expo.easeInOut,
          duration: 1.3,
          yPercent: 100,
          autoAlpha: 0
        }, 'start')
    },

    onLeaveLoader(el, done) {
      const split = new SplitText(this.$refs.title)
      const tl = gsap.timeline({ onComplete: done })

      tl
        .add('start')
        .to(split.chars, {
          duration: 1.2,
          ease: Expo.easeInOut,
          yPercent: 100,
          autoAlpha: 0,
          stagger: 0.013,
        }, 'start')
        .to(this.$refs.loader, {
          duration: 1.3,
          ease: Expo.easeInOut,
          yPercent: 100,
          autoAlpha: 0
        }, 'start')
        .to(el, {
          duration: 1,
          // autoAlpha: 0
          yPercent: -100,
          ease: Expo.easeInOut
        })
    }
  }
}
</script>

<style module lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: z-index(loader);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.title {
  color: $color-white;
  font-size: font-size(l);
  margin-bottom: $small-margin;

}

.loader {
  color: $color-white;
  font-size: 24px;
  display: flex;
  align-items: center;


  span {
    display: block;
    margin: $small-margin;

  }
}

.loading {
  position: relative;
  width: 100px;
  height: 2px;
  background: #222;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-main;
  transform-origin: 0 0;
  transition: all 1s ease-out;
}

.bold {
  font-weight: font-weight(bold)
}

.bigNumber {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70vh;
  color: rgba(20, 20, 20, 1);
  font-weight: font-weight(bold);
}
</style>

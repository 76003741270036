<template>
  <span
    :class="[
      $style.icon,
      $style[color],
      isVisible ? $style.isVisible : ''
    ]"
  >
    <span :class="$style.inner"></span>
  </span>
</template>

<script>
export default {
  props: ['color'],
  data() {
    return {
      isVisible: false
    }
  },

  mounted() {
    this.$scrollbar.addListener(({ offset }) => {
      if (this.$scrollbar.isVisible(this.$el)) {
        this.isVisible = true
      }
    })
  }
}
</script>

<style lang="scss" module>
.icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  z-index: 1;
  margin: 10px;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transform: translate3d(0, 0, 0);
    transition: transform 10.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: -1;
  }

  &.isVisible {

    > span {
      transform: translate3d(6px, 6px, 0);
    }
  }
}

.orange {
  background: $color-orange;

  > span {
    background: $color-orange;
  }
}

.green {
  background: $color-green;

  > span {
    background: $color-green;
  }
}

.violet {
  background: $color-violet;

  > span {
    background: $color-violet;
  }
}
</style>

<template>
  <div data-next-project :class="[$style.next, $style[type]]">
    <Column>
      <span data-animation="text">Next Work</span>
      <WorkBlock
        :class="$style.ok"
        :index="0"
        :type="type"
        :project="project.data"
        :id="project.uid"
        :transition="false"
        @click.native="$store.commit('SET_NEXT', false)"
      />
    </Column>
  </div>
</template>

<script>
import WorkBlock from '@/components/WorkBlock.vue'
import Column from '@/components/Column.vue'

export default {
  props: {
    project: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'project'
    }
  },

  components: {
    Column,
    WorkBlock
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";

  .next {
    padding: $big-margin 0 $standard-margin 0;
    background-color: $color-dark;

    @include mq(sm) {
      padding: $giga-margin 0 $big-margin 0;
    }

    span {
      margin-bottom: $standard-margin;
      display: block;
      text-align: center;
      font-size: 180px;
      font-weight: 600;
      font-size: 80px;
      color: transparent;
      -webkit-text-stroke: 3px $color-main;
      -webkit-text-fill: transparent;
      background-color: rgba(255, 255, 255, 0);
      opacity:.4;

      @include mq(sm) {
        font-size: 180px;
        margin-bottom: $big-margin;
      }
    }

    .ok {
      margin-bottom: 0;
    }
  }
</style>

<template>
  <div :class="[
    $style.cursor,
    isHover ? $style.hover : null,
    $route.name === 'project' ? $style.dark : null,
    isMenuOpen ? $style.light : null,
  ]" >
  </div>
</template>

<script>
import EventBus from '@/scripts/event-bus'
import gsap from 'gsap'
// import drawSVG from 'gsap/DrawSVGPlugin'

const lerp = (a, b, n) => ((1 - n) * a) + (n * b)

export default {
  props: ['isMenuOpen'],

  data: () => ({
    isLerp: true,
    mouse: {
      x: 0,
      y: 0
    },
    interMouse: {
      x: 0,
      y: 0
    },
    isHover: false
  }),

  mounted() {
    this.bindEvents()
    this.render()

    EventBus.$on('hover', () => {
      this.isHover = !this.isHover
    })
  },

  methods: {
    render() {
      this.setPos()
      window.requestAnimationFrame(this.render.bind(this))
    },

    setPos() {
      const lerpedX = lerp(this.mouse.x, this.interMouse.x, 0.2)
      const lerpedY = lerp(this.mouse.y, this.interMouse.y, 0.2)
      this.interMouse.x = this.isLerp ? lerpedX : this.mouse.x
      this.interMouse.y = this.isLerp ? lerpedY : this.mouse.y

      gsap.set(this.$el, {
        x: this.interMouse.x,
        y: this.interMouse.y
      })
    },

    bindEvents() {
      window.addEventListener('mousemove', (event) => {
        this.mouse = {
          x: event.clientX,
          y: event.clientY
        }
      }, false)
    }
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";

$cursor-size-circle: 20px;
$cursor-size: 5px;
$color-cursor: #fff;

.cursor {
  // @include boost-performance();
  position: absolute;
  top: -$cursor-size / 2;
  left: -$cursor-size / 2;
  width: $cursor-size;
  height: $cursor-size;
  z-index: 99;
  pointer-events: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  transform-origin: center;
  background: $color-cursor;
  // transform: scale(1);
  filter: blur(0px);

  &.hover {
    // transform: scale(2);
    filter: blur(15px);
    width: $cursor-size * 1;
    height: $cursor-size * 1;
  }

  @include mq($until:sm) {
    display: none;
  }
}
</style>

import BlackEnter from './BlackEnter'
import BlackLeave from './BlackLeave'
import PageEnter from './PageEnter'
import PageLeave from './PageLeave'

const timelines = new Map()

timelines.set('page-enter', PageEnter)
timelines.set('page-leave', PageLeave)

timelines.set('black-enter', BlackEnter)
timelines.set('black-leave', BlackLeave)

export default timelines
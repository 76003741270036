<template>
  <div :class="$style.block">
    <router-link @mouseenter.native="hover" @mouseleave.native="hover" :to="{ path: `/fun/${id}` }">
      <div :class="$style.cover" :style="{ backgroundColor: project.color }" data-animation="fade-up">
        <img
          ref="img"
          :data-cover="id"
          :src="project.local_url_cover || project.cover.url"
          alt="">
        <div :class="$style.content">
          <strong data-animation="text" :class="$style.subtitle">{{ project.subtitle }}</strong>
          <h4 data-animation="text">{{ project.title }}</h4>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      required: true
    },
    id: { 
      required: true
    },
    transition: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" module>
.block {
  position: relative;
  padding: 80px;
  padding: 15px;
}

.content {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  background: linear-gradient(rgba(#000, 0), rgba(#000, 1));

  h4 {
    color: $color-light;
    margin: 5px 0;
    font-size: font-size(ll);
    font-weight:600;
  }
}

.cover {
  overflow: hidden;
  position: relative;
  background: #000;
  border-radius: 8px;

  &::before {
    display: block;
    width: 100%;
    padding-bottom: 60%;
    content: "";
  }

  img {
    position: absolute;
    top: -10%;
    left: 0;
    display: block;
    width: 100%;
    height: 120%;
    object-fit: cover;
  }
}

.subtitle {
  color: $color-main;
  font-weight: font-weight(medium);
  font-size: font-size(s);
  letter-spacing:5px;
  text-transform:uppercase;
}
</style>

<template>
  <article>
    <Column>
      <div :class="$style.container">
        <h4 data-animation="text" :class="$style.title">{{slice.primary.title1}}</h4>
        <prismic-rich-text data-animation="text" :class="$style.text" :field="slice.primary.text"/>
      </div>
    </Column>
  </article>
</template>

<script>
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  }
}
</script>


<style module lang="scss">
@import "~sass-mq/mq";

.container {
  display: flex;
  padding-top: 0;
  flex-wrap: wrap;
}

.text {
  width: 100%;
  flex: 0 0 auto;


  @include mq(sm) {
    width: 80%;
    flex: 1 1 auto;
  }

  p {
    font-size: font-size(l);
    margin-bottom: 20px;
  }
}

.title {
  width: 100%;
  flex: 0 0 auto;
  font-size: font-size(22);
  font-weight: font-weight(demi);
  color:white;
  text-align:center;

  @include mq(sm) {
    padding-right: 80px;
    font-size: font-size(40);
    width: 80%;
  }
}
</style>

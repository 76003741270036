<template>
  <div :class="{
    [$style.column]: true,
    [$style.large]: large
  }">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.column {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 $small-margin;
  max-width: $container-width;
  margin: 0 auto;

  &.large {
    max-width: $big-container-width;
  }
}
</style>

import Vue from 'vue'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import animations from './animations'
import { isMobile } from '../scripts/utils'

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta
  }
}

Scrollbar.use(ModalPlugin)

class Smooth {
  constructor() {
    this.scrollbar = null
  }

  initScrollbar() {
    return new Promise((resolve) => {
      if (this.scrollbar) {
        resolve()
        return
      }

      const section = document.querySelector('#smoothie')
      this.scrollbar = Scrollbar.init(section, { syncCallbacks: true })

      resolve()
    })
  }

  updateScrollbar() {
    if (this.scrollbar) {
      this.scrollbar.update()
    }
  }

  pauseScrollbar() {
    this.scrollbar.pause()
  }

  destroyScrollbar() {
    return new Promise((resolve) => {
      if (!this.scrollbar) resolve()

      this.scrollbar.destroy()
      this.scrollbar = null
      resolve()
    })
  }

  getScrollbar() {
    return this.scrollbar
  }

  checkDirection(status) {
    if (status.offset.y < this.currentY) {
      this.currentDirection = 'up'
    } else {
      this.currentDirection = 'down'
    }

    this.currentY = status.offset.y || 0
  }

  listenScroll(ctx) {
    this.animateEls = Array.from(this.scrollbar.containerEl.querySelectorAll('[data-animation]'))
    this.images = Array.from(this.scrollbar.containerEl.querySelectorAll('[data-img]'))
    this.currentY = 0
    this.currentDirection = null

    this.scrollbar.addListener((status) => {
      this.checkDirection(status)

      this.animateEls.forEach((el, i) => {
        if (isMobile() && el.getAttribute('data-animation') === 'parallax') return

        if (this.scrollbar.isVisible(el) && el.getAttribute('data-animation') === 'parallax') {
          animations.get('parallax')(el, i)
        }

        if (this.scrollbar.isVisible(el) && !el.hasAttribute('data-animated')) {
          el.setAttribute('data-animated', '')

          const type = el.getAttribute('data-animation')
          animations.get(type)(el)
        }
      })

      if (ctx && ctx.customScrollbar) {
        ctx.customScrollbar(status, this.currentDirection)
      }
    })
  }
}

const smooth = new Smooth()
export default function install() {
  Vue.prototype.$smooth = smooth

  Vue.mixin({
    beforeCreate() {
      this.$options.scrollbar = smooth.scrollbar
    },

    created() {
      const options = this.$options

      // store injection
      if (options.scrollbar) {
        this.$scrollbar = typeof options.scrollbar === 'function'
          ? options.scrollbar : smooth.scrollbar
      } else if (options.parent && options.parent.$scrollbar) {
        this.$scrollbar = options.parent.$scrollbar
      }
    }
  })
}

<template>
  <article>
    <Column>
      <div
        :class="{
          [$style.container]: true,
          [$style.padded]: slice.primary.background1,
          [$style[`align-${slice.primary.type}`]]: true
        }"
      >
        <div v-if="slice.primary.title1" :class="$style.content">
          <h3 data-animation="text" :class="$style.title">{{ slice.primary.title1 }}</h3>
          <div :class="$style.text">
            <prismic-rich-text data-animation="text" :field="slice.primary.text"/>
          </div>
        </div>
        <div :class="$style.image" v-for="(item, index) in slice.items" :key="index">
          <prismic-image data-animation="fade-up" :field="item.image" :key="'photo-item-' + index"/>
        </div>
      </div>
    </Column>
  </article>
</template>

<script>
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  }
}
</script>

<style module lang="scss">
@import "~sass-mq/mq";

.container {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  font-size: font-size(l);

  &.align-right {
    align-items: flex-end;
  }
}

.content {
  @include mq(sm) {
    width: 80%;
    margin-bottom: 50px;
  }
}

.title {
  margin-bottom: 20px;
  font-weight: font-weight(demi);
}

.text {
  margin-bottom: 20px;
}

.image {
  width: 100%;

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    margin:20px 0;
  }
}
</style>

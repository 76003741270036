<template>
  <div :class="[$style.block]" @click="emitGlobalClickEvent">
    <router-link
      @mouseenter="hover"
      @mouseleave="hover"
      :class="$style.linkImage"
      :to="{ path: `/${type}/${id}` }">
      <div :class="$style.cover" data-animation="fade-up">
        <img
          ref="img"
          data-cover-block
          :data-cover="id"
          :src="project.local_url_cover || project.cover.url">
      </div>
    </router-link>
    <div :class="$style.content">
      <strong data-animation="text" :class="$style.subtitle">
        {{ project.subtitle }}\
      </strong>
      <h4 data-animation="text">
        {{ project.title }}
      </h4>
      <router-link
        @mouseenter="hover"
        @mouseleave="hover"
        data-animation="text"
        :to="{ path: `/${type}/${id}` }">
        See Project
      </router-link>
    </div>
  </div>
</template>

<script>
import EventBus from '@/scripts/event-bus'
import { qs } from '@/scripts/utils'

export default {
  props: {
    project: {
      required: true
    },
    id: {
      required: true
    },
    index: {
      required: true
    },
    theme: {
      default: 'light'
    },
    type: {
      type: String,
      default: 'project'
    }
  },
  methods: {
    emitGlobalClickEvent() {
      if (this.type === 'project') {
        this.$store.commit('SET_LAST_Y', this.$smooth.scrollbar.scrollTop)
        EventBus.$emit('work-click', this.id, qs('[data-cover-block]', this.$el))
      }
    }
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";

.block {
  margin-bottom: 100px;

  @include mq(sm) {
    margin-bottom: 250px;
  }

  :global(.no-touchevents) &:hover {
    a::after {
      transform: scaleX(0);
    }
  }

  &.dark {
    .subtitle {
      color: rgba(0, 0, 0, 1);
    }

    .content {
      h4 {
        color: $color-dark;
      }

      a {
        display: none;
      }
    }
  }
}

.content {
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);

  h4 {
    color: $color-light;
    margin-bottom: $small-margin;
    margin-top: 4px;
    font-weight: 600;
    font-size:28px;

    @include mq(sm) {
      font-size: 64px;
    }

  }

  a {
    position: relative;
    display: inline-block;
    padding: 2px 0;
    font-size: font-size(m);
    font-weight: font-weight(medium);
    color: $color-light;

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      background: $color-main;
      content: "";
      transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
      transform-origin: 0 0;
      transform: scaleX(1);
    }
  }
}

.linkImage {
  display: block;
  overflow: hidden;
}

.cover {
  overflow: hidden;
  position: relative;
  background: #000;
  border-radius: 8px;

  img {
    display: block;
    width: 100%;
  }
}

.subtitle {
  color: $color-main;
  opacity:0.7;
  font-weight: font-weight(medium);
  font-size: font-size(m);
  letter-spacing:5px;
  line-height:170%;
  text-transform:uppercase;
}

.description {
  line-height: 170%;
}
</style>

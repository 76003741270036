<template>
<article>
  <Column>
    <div :class="$style.container">
      <div ref="container">
        <div ref="fixed" :class="$style.fixed">
          <h3 data-animation="text" :class="$style.title">{{ slice.primary.title1 }}</h3>
          <prismic-rich-text data-animation="fade-up" :field="slice.primary.text"/>
        </div>
      </div>
      <div :class="$style.column">
        <prismic-image
          v-for="(item, index) in slice.items"
          :key="index"
          data-animation="fade-up"
          :field="item.image"
        />
      </div>
    </div>
  </Column>
</article>
</template>

<script>
import Column from '@/components/Column.vue'

/* eslint-disable */
Number.prototype.between = function (a, b) {
  var min = Math.min.apply(Math, [a, b])
  var max = Math.max.apply(Math, [a, b])
  return this > min && this < max
}
/* eslint-enable */

export default {
  props: ['slice'],

  components: {
    Column
  },

  mounted() {
    const b = this.$refs.container.getBoundingClientRect()
    this.$refs.fixed.style.width = `${b.width}px`

    this.$scrollbar.addListener(({ offset }) => {
      if (this.$refs.container) {
        const bounds = this.$refs.container.getBoundingClientRect()
        const condition = -bounds.height + this.$refs.fixed.clientHeight

        if (bounds.top.between(0, condition)) {
          this.$refs.fixed.style.width = `${bounds.width}px`
          this.$refs.fixed.style.top = `${offset.y}px`
          this.$refs.fixed.style.left = `${bounds.left}px`
        }
      }
    })
  }
}
</script>

<style module lang="scss">
@import "~sass-mq/mq";

.container {
  display: flex;
  flex-wrap: wrap;
  font-size: font-size(l);

  > div {
    width: 100%;
    flex: 0 0 auto;

    @include mq(sm) {
      width: 50%;
    }
  }
}

.fixed {
  padding-right: 50px;
  padding-bottom: 50px;

  @include mq(sm) {
    position: fixed;
    padding-bottom: 0;
  }
}

.title {
  padding-top: 100px;
  // font-size: font-size(40);
}

.column {
  display: flex;
  flex-flow: column;

  img {
    display: block;
    width: 100%;
    margin:20px 0;
  }
}
</style>

import gsap, { Expo } from 'gsap'

const PageEnter = (page, to) => {
  const tl = gsap.timeline({ paused: true })

  if (to === 'project' || to === 'fun') {
    tl
      .set(page, {
        clearProps: 'all'
      })
      .fromTo('[data-title]',  {
        duration: 1,
        autoAlpha: 0,
        y: 30
      }, {
        autoAlpha: 1,
        y: 0,
        ease: Expo.easeInOut
      })
      .set(document.body, { clearProps: 'all' })
  } else {
    tl
      .set(document.body, {
        clearProps: 'all'
      })
  }

  return tl
}

export default PageEnter

<template>
  <div :class="$style.block">
    <Column :large="true">
      <div :class="$style.bg">
        <div :class="$style.layout">
          <h2 :class="$style.title">Awards</h2>
          <ul :class="$style.list">
            <li v-for="award in awards" :key="award.key">
              <strong>{{ $prismic.richTextAsPlain(award.award) }}</strong>
              <h3>{{ $prismic.richTextAsPlain(award.type) }}</h3>
            </li>
          </ul>
        </div>
      </div>
  </Column>
  </div>
</template>

<script>
import Column from '@/components/Column'

export default {
  props: ['awards'],
  components: {
    Column
  }
}
</script>

<style module lang="scss">
.block {
  overflow: hidden;
}

.layout {
  padding: 20vh 0;
  display: flex;
  align-items: center;
}

.bg {
  position: relative;
  width: 100%;

  // &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: -50px;
  //     bottom: 0;
  //     width: 100vw;
  //     display: block;
  //     background: #000;
  //     z-index: -1;
  //   }
}

.title {
  width: 60%;
  flex: 0 0 auto;
  font-size: font-size(xxl);
}

.list {
  flex: 0 0 auto;

  li {
    margin: $standard-margin 0;
  }

  h3 {
    font-size: font-size(ll);
  }

  strong {
    color: rgba(255, 255, 255, 0.4);
    font-weight: font-weight(regular);

  }
}
</style>

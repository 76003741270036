<template>
  <article>
    <Column>
      <video data-animation="fade-up" controls :class="$style.video" :src="slice.primary.video.url"></video>
    </Column>
  </article>
</template>

<script>
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  }
}
</script>

<style lang="scss" module>
.video {
  width: 100%;
  outline: 0;
}
</style>

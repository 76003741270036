import {
  Uniform,
  Vector2,
  Texture,
  LinearFilter,
  ClampToEdgeWrapping,
  WebGLRenderer,
  PerspectiveCamera,
  ShaderMaterial,
  Scene,
  DoubleSide,
  Mesh,
  PlaneBufferGeometry,
  MeshStandardMaterial
} from 'three'

import gsap, { Expo, Power3 } from 'gsap'
import EventBus from '../event-bus'
import frag from './frag.glsl'
import vert from './vert.glsl'

class Engine {
  constructor() {
    this.initialised = false
    this.camera = null
    this.scene = null
    this.renderer = null
    this.timing = 2.2

    this.uniforms = {
      uImage: new Uniform(null),
      uImageRes: new Uniform(new Vector2(1, 1)),

      // Calculated Uniforms
      uScrollY: new Uniform(0),
      uProgress: new Uniform(0),
      uMeshScale: new Uniform(new Vector2(1, 1)),
      uPlaneCenter: new Uniform(new Vector2(0, 0)),
      uViewSize: new Uniform(new Vector2(1, 1)),
      uScaleToViewSize: new Uniform(new Vector2(1, 1)),
      uClosestCorner: new Uniform(0)
    }

    this.textures = []

    this.currentTexture = null
    this.isAnimating = false
    this.onResize = this.onResize.bind(this)

    EventBus.$on('work-click', (id, el) => {
      console.log(id, el)
      this.toFullscreen(id, el)
    })

    EventBus.$on('exit-work', (immediate = false) => {
      console.log(immediate)
      this.toThumb(immediate)
    })
  }

  getViewSize() {
    const fovInRadians = (this.camera.fov * Math.PI) / 180
    const height = Math.abs(this.camera.position.z * Math.tan(fovInRadians / 2) * 2)

    return { width: height * this.camera.aspect, height }
  }

  createTextures(images) {
    const textures = []

    images.forEach((img) => {
      const text = new Texture(img)

      //   // So It doesnt get resized to the power of 2
      text.generateMipmaps = false
      text.wrapS = ClampToEdgeWrapping
      text.wrapT = ClampToEdgeWrapping
      text.minFilter = LinearFilter
      text.needsUpdate = true

      textures.push({
        element: img,
        texture: text,
        id: img.getAttribute('data-cover')
      })
    })


    this.textures = textures
    this.setCurrentTexture()
  }

  getTexture(id) {
    console.log(this.textures)
    return this.textures.find(text => text.id === id)
  }

  setCurrentTexture(id) {
    if (!this.currentTexture) return

    const textureSet = this.getTexture(id || this.currentTexture.id)

    if (textureSet) {
      const { naturalWidth, naturalHeight } = textureSet.texture.image
      this.uniforms.uImage.value = textureSet.texture
      this.uniforms.uImageRes.value.x = naturalWidth
      this.uniforms.uImageRes.value.y = naturalHeight
    }

    if (!this.isAnimating) {
      this.render()
    }
  }

  init() {
    this.container = document.querySelector('#canvas')
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.container.appendChild(this.renderer.domElement)

    this.scene = new Scene()
    this.camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000)
    this.camera.position.z = 50
    this.camera.lookAt = this.scene.position

    const viewSize = this.getViewSize()
    this.uniforms.uViewSize.value = new Vector2(viewSize.width, viewSize.height)

    const segments = 256
    const geometry = new PlaneBufferGeometry(1, 1, segments, segments)

    const material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vert,
      fragmentShader: frag,
      side: DoubleSide
    })

    this.mesh = new Mesh(geometry, material)
    this.scene.add(this.mesh)

    window.addEventListener('resize', this.onResize)
  }

  recalculateUniforms(rect) {
    // if (!this.currentTexture) return

    const viewSize = this.getViewSize()
    console.log(viewSize)
    const widthViewUnit = (rect.width * viewSize.width) / window.innerWidth
    const heightViewUnit = (rect.height * viewSize.height) / window.innerHeight

    // x and y are based on top left of screen. While ThreeJs is on the center
    const xViewUnit = ((rect.left * viewSize.width) / window.innerWidth) - (viewSize.width / 2)
    const yViewUnit = ((rect.top * viewSize.height) / window.innerHeight) - (viewSize.height / 2)

    const xIndex = rect.left > window.innerWidth - (rect.left + rect.width)
    const yIndex = rect.top > window.innerHeight - (rect.top + rect.height)
    const closestCorner = (xIndex * 2) + yIndex
    this.uniforms.uClosestCorner.value = closestCorner

    // // The plain's size is initially 1. So the scale is the new size
    this.mesh.scale.x = widthViewUnit
    this.mesh.scale.y = heightViewUnit
    console.log(widthViewUnit)

    // // Move the object by its top left corner, not the center
    const x = xViewUnit + (widthViewUnit / 2)
    const y = -yViewUnit - (heightViewUnit / 2)

    // geometry.translate(x, y, 0);
    this.mesh.position.x = x
    this.mesh.position.y = y

    // Used to scale the plane from the center
    // divided by scale so when later scaled it looks fine
    this.uniforms.uPlaneCenter.value.x = x / widthViewUnit
    this.uniforms.uPlaneCenter.value.y = y / heightViewUnit

    this.uniforms.uMeshScale.value.x = widthViewUnit
    this.uniforms.uMeshScale.value.y = heightViewUnit

    this.uniforms.uScaleToViewSize.value.x = (viewSize.width / widthViewUnit) - 1
    this.uniforms.uScaleToViewSize.value.y = (viewSize.height / heightViewUnit) - 1
  }

  toFullscreen(id, el) {
    if (this.isAnimating) return

    this.setY(0)
    this.isAnimating = true
    this.currentTexture = this.getTexture(id)

    if (this.currentTexture) {
      this.setCurrentTexture()
    }

    const rect = el.getBoundingClientRect()
    console.log(el.getBoundingClientRect())
    this.recalculateUniforms(rect)


    gsap.set(this.container, { zIndex: 19 })
    gsap.fromTo(this.uniforms.uProgress, {
      duration: this.timing,
      value: 0
    }, {
      value: 1,
      ease: 'expo.easeInOut',
      onUpdate: () => {
        this.render()
      },
      onComplete: () => {
        this.isAnimating = false
        this.render()
        gsap.set(this.container, { zIndex: -1 })
      }
    })
  }

  resetUniforms() {
    this.uniforms.uMeshScale.value = new Vector2(1, 1)
    this.uniforms.uPlaneCenter.value = new Vector2(0, 0)
    this.uniforms.uScaleToViewSize.value = new Vector2(1, 1)

    this.uniforms.uImage.value = null
    // this.uniforms.uImageRes.value = new Vector2(1, 1)

    const { scale, position } = this.mesh
    scale.x = 0.0001
    scale.y = 0.0001
    position.x = 0
    position.y = 0
  }

  toThumb(immediate) {
    if (this.isAnimating) return

    this.setY(0)
    this.isAnimating = true

    const time = immediate ? 0 : 1.8
    gsap.set(this.container, { zIndex: 19 })
    gsap.to(this.uniforms.uProgress, {
      duration: time,
      value: 0,
      ease: Power3.easeInOut,
      onUpdate: () => {
        this.render()
      },
      onComplete: () => {
        this.isAnimating = false
        this.resetUniforms()
        this.render()
        gsap.set(this.container, { zIndex: -1 })
        this.currentTexture = null
      }
    })
  }

  setY(y) {
    if (this.isAnimating) return
    this.uniforms.uScrollY.value = y
  }

  render() {
    this.renderer.render(this.scene, this.camera)
  }

  onResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight)

    if (this.currentTexture) {
      this.recalculateUniforms({
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: window.innerWidth,
        height: window.innerHeight
      })

      this.render()
    }
  }
}

export default new Engine()

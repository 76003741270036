<template>
  <div :class="[$style.container]">
    <Hero ref="hero" :id="project" :data="data"/>
    <div v-if="data" style="background-color: #111; padding-top: 100px">
      <Column :class="$style.intro">
        <span data-animation="text">{{data.subtitle}}</span>
        <h1 data-animation="text">{{data.title}}</h1>
      </Column>
      <div :class="$style.wrapper">
        <div
          :class="[
            $style.row,
            slice.primary.line === 'true' ? $style.bordered : null
          ]"
          :style="{ backgroundColor: slice.primary.background1 }"
          v-for="(slice, index) in data.body"
          :key="index">
          <component
            :is="slice.slice_type"
            :slice="slice"
          />
        </div>
      </div>
      <Next :project="next"/>
    </div>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Next from '@/components/Next.vue'
import Column from '@/components/Column.vue'
import TextOnly from '@/components/project/TextOnly.vue'
import BigImage from '@/components/project/BigImage.vue'
import Sticky from '@/components/project/Sticky.vue'
import Slideshow from '@/components/project/Slideshow.vue'
import VideoPlayer from '@/components/project/VideoPlayer.vue'
import Gallery from '@/components/project/Gallery.vue'
import Flag from '@/components/project/Flag.vue'
import UxPersona from '@/components/project/UxPersona.vue'
import EventBus from '@/scripts/event-bus'
import Engine from '@/scripts/webgl'
import { qs } from '@/scripts/utils'

export default {
  props: ['project'],
  name: 'project',

  data() {
    return {
      data: {
        notFrom: false
      }
    }
  },

  components: {
    Hero,
    Column,
    flag: Flag,
    slideshow: Slideshow,
    sticky: Sticky,
    gallery: Gallery,
    big_image: BigImage,
    'video-player': VideoPlayer,
    text_only: TextOnly,
    ux_persona: UxPersona,
    Next
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!from.name) {
        vm.notFrom = true
      }
    })
  },

  created() {
    this.$smooth.initScrollbar().then(() => {
      this.$smooth.listenScroll(this)
    })

    this.data = this.$store.getters.getProject(this.project)
    this.next = this.$store.getters.getNextProject(this.project)
  },

  mounted() {
    this.$nextTick(() => {
      const images = Array.from(document.querySelectorAll('[data-cover]'))
      Engine.setY(0)

      if (!Engine.textures.length) {
        Engine.setCurrentTexture(this.project)
        Engine.createTextures(images)
        if (this.notFrom) {
          EventBus.$emit('work-click', this.project, this.$refs.hero.$el)
        }
      }
    })
  },

  methods: {
    checkFooter({offset, limit}) {
      const nextH = qs('[data-next-project]').getBoundingClientRect().height
      if (offset.y >= (limit.y - nextH)) {
        if (this.$store.state.isFooterLight) return

        this.$store.commit('SET_FOOTER_LIGHT', true)
      } else if (offset.y < (limit.y - nextH)) {
        if (this.$store.state.isFooterLight) {
          this.$store.commit('SET_FOOTER_LIGHT', false)
        }
      }
    },
    checkHeader({offset, limit}) {
      const nextH = qs('[data-next-project]').getBoundingClientRect().height

      if ((offset.y) >= (limit.y - (nextH - window.innerHeight))) {
        if (this.$store.state.isHeaderLight) return

        this.$store.commit('SET_HEADER_LIGHT', true)
      } else if ((offset.y) < (limit.y - (nextH - window.innerHeight))) {
        if (this.$store.state.isHeaderLight) {
          this.$store.commit('SET_HEADER_LIGHT', false)
        }
      }
    },

    customScrollbar(status) {
      Engine.setY((status.offset.y / window.innerHeight) * 0.6)
      Engine.render()
      this.checkCaption(status)
    }
  }
}
</script>


<style lang="scss" module>
@import "~sass-mq/mq";

.container {
  p {
    opacity: 0.6;
    color:white;

    a {
      color: white;
    }
  }

  h3 {
    font-weight: font-weight(demi);
    color: white;
  }

  .intro {

    span {
      font-weight: font-weight(demi);
      color: $color-main;
      letter-spacing:5px;
      text-transform:uppercase;
    }

    h1 {
      font-size: 40px;
      font-weight: font-weight(demi);
      border-bottom: 3px solid $color-main;
      padding-bottom: 40px;
      color:white;

      @include mq(sm) {
        font-size: 72px;
        padding-bottom: 80px;
      }
    }
  }
}

.content {
  padding-top: 40px;

  @include mq(sm) {
    padding-top: 80px;
  }
}

.wrapper {
  a:not([class]),
  a:visited {
    color: #fff;
    opacity: 1;
    font-weight: 500;
  }

  > div:first-of-type {
    padding-top: $big-margin-mobile;

    @include mq(sm) {
      padding-top: $giga-margin / 2;
    }
  }
}

.row {
  position: relative;
  padding: $big-margin-mobile 0;

  @include mq(sm) {
    padding: $giga-margin 0;

    &.bordered {
      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        max-width: $container-width;
        border-bottom: 3px solid $color-main;
      }
    }
  }

}
</style>

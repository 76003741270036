<template>
<article  :class="$style.container">
  <Column>
    <div data-animation="text" :class="$style.text">
      <h3>{{ slice.primary.title1 }}</h3>
      <prismic-rich-text :field="slice.primary.text"/>
    </div>
  </Column>
  <div data-animation="fade-up" :class="$style.carousel">
    <div :class="$style.leftTrigger" @click.prevent="next" ></div>
    <div :class="$style.rightTrigger" @click.prevent="prev" ></div>
    <div
      ref="slides"
      :class="$style.slides">
      <div
        ref="slide"
        :class="$style.image"
        v-for="(item, index) in slice.items"
        :key="index">
        <img :src="item.image.url">
      </div>
    </div>
    <div :class="$style.dots">
      <span
        :class="[$style.dot, index === currentIndex && $style.isActive]"
        v-for="(item, index) in slice.items"
        :key="index" />
    </div>
  </div>
</article>
</template>

<script>
import Hammer from 'hammerjs'
import { Expo, TimelineMax } from 'gsap'
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  },

  data() {
    return {
      currentIndex: 0,
      isAnimating: false
    }
  },

  mounted() {
    this.mc = new Hammer.Manager(this.$refs.slides)
    const Swipe = new Hammer.Swipe()
    this.mc.add(Swipe)
    this.mc.on('swipeleft', this.next)
    this.mc.on('swiperight', this.prev)
  },

  methods: {
    next() {
      if (this.currentIndex >= this.slice.items.length - 1 || this.isAnimating) return

      this.isAnimating = true
      this.currentIndex = this.currentIndex + 1
      this.animate()
    },

    prev() {
      if (this.currentIndex <= 0 || this.isAnimating) return

      this.isAnimating = true
      this.currentIndex = this.currentIndex - 1
      this.animate()
    },

    animate() {
      const tl = new TimelineMax({
        onComplete: () => {
          this.isAnimating = false
        }
      })

      this.oldScale = this.currentScale
      this.currentScale = this.$refs.slide[this.currentIndex].querySelector('img')

      tl
      .to(this.$refs.slides, 1.4, {
        x: - this.$refs.slides.clientWidth * this.currentIndex,
        ease: Expo.easeInOut
      }, 'start')
      .to(this.currentScale, 1.5, {
        scale: 1,
        ease: Expo.easeInOut
      }, 'start')

      if (this.oldScale) {
        tl.to(this.oldScale, 1.5, {
          scale: 1.1,
          ease: Expo.easeInOut
        }, 'start')
      }
    }
  }
}
</script>

<style module lang="scss">
@import "~sass-mq/mq";

.container {
  overflow: hidden;
}

.text {
  margin-bottom: $standard-margin;
  font-size: font-size(20);

  @include mq(sm) {
    width: 50%;
  }
}

.carousel {
  position: relative;
  max-width: $container-width;
  margin: 0 auto;
  width: 60%;

  @include mq(sm) {
    width: 50%;
  }
}

.slides {
  position: relative;
  z-index: 0;
  display: flex;
  will-change: transform;
  margin-left: -10px;
  margin-right: -10px;

  @include mq(sm) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.leftTrigger,
.rightTrigger {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 10;

  @include mq(sm) {
    width: 50%;
  }

}

.leftTrigger {
  right: 0;
  @include mq(sm) {
    right: auto;
    left: 100%;
  }
}

.rightTrigger {
  left: 0;
  // background: red;
  @include mq(sm) {
    left: auto;
    right: 100%;
  }
}

.image {
  position: relative;
  margin: 0 auto;
  flex: 0 0 auto;
  overflow: hidden;
  width: calc(100% - 20px);
  margin: 0 10px;

  @include mq(sm) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  img {
    width: 100%;
    transform: scale(1.1);
    will-change: transform;
  }
}

.dots {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;

  @include mq(sm) {
    margin: 20px 0;
  }

  .dot {
    display: block;
    height: 2px;
    margin: 0 2px;
    width: 20px;
    background: $color-dark;
    opacity: 0.4;
    transition: all 0.4s ease-out;

    @include mq(sm) {
      margin: 0 5px;
      width: 50px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &.isActive {
      opacity: 1;
    }
  }
}
</style>

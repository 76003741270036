import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projects: {},
    fun: {},
    home: {},
    smooth: null,
    lastY: 0,
    isHeaderLight: false,
    isFooterLight: false,
    nextOneTime: false
  },
  mutations: {
    /* eslint-disable */
    SET_PROJECTS(state, data) {
      state.projects = data
    },

    SET_HEADER_LIGHT(state, bool = false) {
      state.isHeaderLight = bool
    },

    SET_FOOTER_LIGHT(state, bool = false) {
      state.isFooterLight = bool
    },

    SET_FUN(state, data) {
      state.fun = data
    },

    SET_HOME(state, data) {
      state.home = data
    },

    SET_LAST_Y(state, position) {
      state.lastY = position
    },

    SET_NEXT(state, bool = false) {
      state.nextOneTime = bool
    }
    /* eslint-enable */
  },

  getters: {
    projects(state) {
      return state.projects.results
    },

    fun(state) {
      return state.fun.results
    },

    home(state) {
      return state.home.data
    },

    getProject: state => id => state.projects.results.find(project => id === project.uid).data,
    getProjectIndex: state => id => state.projects.results
      .indexOf(state.projects.results.find(project => id === project.uid)),
    getNextProject: (state, getters) => (id) => {
      const currentIndex = getters.getProjectIndex(id)
      return currentIndex < getters.projects.length - 1 ?
        getters.projects[currentIndex + 1] : getters.projects[0]
    },

    getFun: state => id => state.fun.results.find(project => id === project.uid).data,
    getFunIndex: state => id => state.fun.results
      .indexOf(state.fun.results.find(project => id === project.uid)),
    getNextFun: (state, getters) => (id) => {
      const currentIndex = getters.getFunIndex(id)
      return currentIndex < getters.fun.length - 1 ?
        getters.fun[currentIndex + 1] : getters.fun[0]
    }
  },

  /* eslint-disable */
  actions: {
    async FETCH_PROJECTS({ commit }) {
      await this._vm.$prismic.client
        .query(this._vm.$prismic.Predicates.at('document.type', 'project'),
        { orderings : '[document.last_publication_date desc]' })
        .then(data => commit('SET_PROJECTS', data))
    },

    async FETCH_FUN({ commit }) {
      await this._vm.$prismic.client
        .query(this._vm.$prismic.Predicates.at('document.type', 'fun'),
        { orderings : '[document.last_publication_date desc]' })
        .then(data => commit('SET_FUN', data))
    },

    async FETCH_HOME({ commit }) {
      await this._vm.$prismic.client.getSingle('homepage')
        .then(data => commit('SET_HOME', data))
    }
  }
  /* eslint-enable */
})

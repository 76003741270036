import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Project from './views/Project.vue'
import Fun from './views/Fun.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/project/:project',
      name: 'project',
      component: Project,
      props: true
    },
    {
      path: '/fun/:project',
      name: 'fun',
      component: Fun,
      props: true
    }
  ]
})

<template>
  <div id="app">
    <Navigation @trigger-menu="triggerMenu" v-if="!isLoading" />
    <RotatingBadge v-if="!isLoading" :isMenuOpen="isMenuOpen" />
    <!-- <Menu :isOpen="isMenuOpen" /> -->
    <CstmCursor :isMenuOpen="isMenuOpen" />
    <div id="canvas"></div>
    <div id="black-layer"></div>
    <Loader v-if="isLoading" :percent="percent" :fetchData="fetchData" />
    <div ref="smoothie" id="smoothie">
      <div>
        <transition
          @enter="routeEnter"
          @leave="routeLeave"
          mode="out-in"
          :css="false"
        >
          <router-view :key="$route.fullPath" v-if="!isLoading" />
        </transition>
      </div>
    </div>
    <div id="caption">
      <div id="caption-child">KEEP <span></span> SCROLLING</div>
    </div>
    <div class="c-scrolldown" id="scroll-down">
      <div class="c-line"></div>
    </div>
  </div>
</template>

<script>
import quickLoader from "quick-loader";
import gsap, { Expo } from "gsap";
import { mapGetters, mapState } from "vuex";

import EventBus from "@/scripts/event-bus";
import Engine from "@/scripts/webgl";
import timelines from "@/timelines/index";
import Navigation from "@/components/Navigation.vue";
import Loader from "@/components/Loader.vue";
import CstmCursor from "@/components/Cursor.vue";
import RotatingBadge from "@/components/RotatingBadge.vue";
// import Menu from '@/components/Menu.vue'

export default {
  components: {
    Navigation,
    Loader,
    CstmCursor,
    RotatingBadge,
    // Menu
  },

  data() {
    return {
      percent: 0,
      isMenuOpen: false,
      isLoading: true,
      isFirstEnter: true,
    };
  },

  computed: {
    ...mapGetters(["projects", "fun"]),
    ...mapState(["nextOneTime"]),
  },

  created() {
    this.$router.beforeEach((to, from, next) => {
      this.fromName = from.name;
      this.toName = to.name;

      next();
    });
  },

  methods: {
    triggerMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    checkTransition(from, to) {
      return this.toName === to && this.fromName === from;
    },

    routeEnter(element, done) {
      if (this.isFirstEnter) {
        this.isFirstEnter = false;
        done();
        return;
      }

      if (this.checkTransition("project", "home") && this.nextOneTime) {
        timelines
          .get("page-enter")(element, this.toName)
          .add(() => {
            done();
            this.$smooth.updateScrollbar();
            this.$smooth.scrollbar.scrollTop = this.$store.state.lastY;
          })
          .play();
      } else {
        timelines
          .get("black-enter")(element, this.toName)
          .add(() => {
            done();
            this.$smooth.updateScrollbar();
          })
          .play();
      }
    },

    routeLeave(element, done) {
      if (this.checkTransition("project", "home") && this.nextOneTime) {
        this.$smooth.scrollbar.scrollTo(0, 0, 800, {
          callback: () => {
            EventBus.$emit("exit-work");
            gsap.to(element, {
              duration: 0.6,
              autoAlpha: 0,
            });
            this.$smooth.destroyScrollbar();
            done();
          },
        });
      } else if (this.checkTransition("fun", "home")) {
        this.$smooth.scrollbar.scrollTo(0, 0, 800, {
          callback: () => {
            timelines
              .get("black-leave")(element, done)
              .add(() => {
                Engine.resetUniforms();
                this.$smooth.destroyScrollbar();
                done();
              })
              .play();
          },
        });
      } else if (
        this.checkTransition("home", "project") ||
        this.checkTransition("project", "project")
      ) {
        timelines
          .get("page-leave")(element, done)
          .add(() => {
            this.$smooth.destroyScrollbar();
            done();
          })
          .play();
      } else {
        timelines
          .get("black-leave")(element, done)
          .add(() => {
            EventBus.$emit("exit-work", true);
            Engine.resetUniforms();
            this.$smooth.destroyScrollbar();
            done();
          })
          .play();
      }

      gsap.to("#caption-child", {
        duration: 0.6,
        autoAlpha: 0,
        ease: Expo.easeInOut,
      });
    },

    fetchData() {
      Promise.all([
        this.$store.dispatch("FETCH_PROJECTS"),
        this.$store.dispatch("FETCH_FUN"),
        this.$store.dispatch("FETCH_HOME"),
      ])
        .then(this.preloadAssets)
        .then(() => {
          this.isLoading = false;
        });
    },

    preloadAssets() {
      const urls = [...this.projects].map(
        (proj) => proj.data.local_url_cover || proj.data.cover.url
      );

      urls.push("/img/stefano-photo-about.jpg");

      Engine.init();
      quickLoader.addChunk(urls);
      console.log(urls);

      return new Promise((resolve) => {
        quickLoader.start((p, i) => {
          this.percent = p;

          if (this.percent === 1) {
            resolve();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/style.scss";

#smoothie {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

#app {
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: $font-family-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#caption {
  position: fixed;
  left: 0;
  bottom: -40px;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 2;
  font-size: font-size(12);
  letter-spacing: 2px;
  color: white;
  transform: translateX(calc(-50% + #{$standard-margin}));
  font-weight: normal;
  z-index: 10;
  pointer-events: none;

  #caption-child {
    display: none;
    align-items: center;
    transform: rotate(-90deg);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;

    @include mq(sm) {
      display: flex;
    }
  }

  span {
    display: block;
    width: 60px;
    opacity: 1;
    height: 1px;
    background: $color-main;
    margin: 0 15px;
  }
}

#black-layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  z-index: 100;
  pointer-events: none;
  display: none;
}

$line-height: 60px;
$line-height-abs: -60px;

.c-scrolldown {
  width: 1px;
  height: $line-height;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  margin: $standard-margin $small-margin;
  z-index: 9;

  @include mq(sm) {
    margin: $standard-margin;
  }

  .c-line {
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      to bottom,
      $color-main 50%,
      rgba(255, 255, 255, 0) 50%
    );
    background-position: 0 $line-height-abs;
    background-size: 100% 200%;
    animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
  }
}

@keyframes scrolldown {
  0% {
    background-position: 0 $line-height-abs;
  }
  75% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 $line-height;
  }
}
</style>

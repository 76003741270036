<template>
  <div :class="$style.home">
    <section :class="$style.content">
      <div :class="$style.intro">
        <Column :large="true">
          <div :class="$style.half">
            <!-- eslint-disable -->
            <h2 ref="intro">
              Hello 👋 I'm
              <span @mouseenter="showPic('stefano')" @mouseleave="hidePic()"
                >Stefano</span
              >, a Remote Product Designer based in
              <span @mouseenter="showPic('milan')" @mouseleave="hidePic()"
                >Milan</span
              >
              currently making interfaces at
              <span @mouseenter="showPic('mirum')" @mouseleave="hidePic()"
                >MetaLab</span
              ><br />
            </h2>
            <!-- eslint-enable -->
            <strong ref="outro">
              I'm a product designer with several years of experience in the
              design industry, with a special focus on visual and product
              design, UI animations, design systems and digital interactive
              experiences.
            </strong>
          </div>
          <div :class="$style['hover-gif']" v-if="!isMobile()">
            <img
              ref="stefano"
              src="/img/stefano-pic.png"
              :class="$style.vertical"
            />
            <img ref="mirum" src="/img/metalab.jpg" :class="$style.vertical" />
            <img
              ref="milan"
              src="/img/milan-pic.jpg"
              :class="$style.vertical"
            />
          </div>
        </Column>
      </div>
      <!-- <Skills/> -->
      <div :class="$style.column">
        <Heading>
          <slot name="title"> Latest Works </slot>
          <slot name="subtitle">
            A selection of some projects I've been working on.
          </slot>
        </Heading>
        <Column :large="true">
          <div
            :class="$style.projects"
            v-for="(project, i) in projects"
            :key="project.title"
          >
            <WorkBlock
              :index="i"
              :transition="true"
              :project="project.data"
              :id="project.uid"
              @click="$store.commit('SET_NEXT', true)"
            />
          </div>
        </Column>
      </div>
      <div :class="$style.column">
        <Heading>
          <slot name="title"> Just for fun </slot>
          <slot name="subtitle">
            Some personal projects made mostly for fun.
          </slot>
        </Heading>
        <Column :large="true">
          <div :class="$style.funnies">
            <FunBlock
              v-for="project in fun"
              :project="project.data"
              :id="project.uid"
              :key="project.title"
            />
          </div>
        </Column>
      </div>
      <Heading>
        <slot name="title"> Get in touch </slot>
        <slot name="subtitle"> Drop me a message and let's talk! </slot>
      </Heading>
      <!-- <Awards :awards="home.awards"/> -->
      <Footer />
    </section>
  </div>
</template>

<script>
import gsap, { Expo, Power2 } from "gsap";
import { mapGetters } from "vuex";

import EventBus from "@/scripts/event-bus";
import animations from "@/scrollbar/animations";
import Engine from "@/scripts/webgl";
import Column from "@/components/Column.vue";
import Skills from "@/components/Skills.vue";
import Heading from "@/components/Heading.vue";
import WorkBlock from "@/components/WorkBlock.vue";
import FunBlock from "@/components/FunBlock.vue";
import Awards from "@/components/Awards.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "home",
  components: {
    Column,
    Skills,
    Heading,
    WorkBlock,
    FunBlock,
    Awards,
    Footer,
  },

  computed: {
    ...mapGetters(["projects", "home", "fun"]),
  },

  mounted() {
    animations.get("stagger")(this.$refs.intro);
    animations.get("stagger")(this.$refs.outro);

    this.$nextTick(() => {
      const images = Array.from(document.querySelectorAll("[data-cover]"));
      if (!Engine.textures.length) {
        Engine.createTextures(images);
      }
    });
  },

  created() {
    this.$smooth.initScrollbar().then(() => {
      this.$smooth.listenScroll(this);
    });
  },

  methods: {
    customScrollbar(status) {
      this.$smooth.images.forEach((el) => {
        if (
          this.$smooth.scrollbar.isVisible(el) &&
          !el.hasAttribute("data-animated")
        ) {
          el.setAttribute("data-animated", "");
        }
      });

      this.checkCaption(status);
    },

    showPic(id) {
      EventBus.$emit("hover");
      this.currentImage = id;

      if (!this.isMobile()) {
        gsap.fromTo(
          this.$refs[id],
          {
            duration: 0.6,
            autoAlpha: 0,
            yPercent: 20,
          },
          {
            autoAlpha: 1,
            yPercent: 0,
            display: "block",
            ease: Power2.easeOut,
          }
        );
      }
    },

    hidePic() {
      EventBus.$emit("hover");
      gsap.to(this.$refs[this.currentImage], {
        duration: 0.8,
        autoAlpha: 0,
        yPercent: -20,
        display: "none",
        ease: Expo.easeOut,
        clearProps: "all",
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "@/style.scss";

.home {
  min-height: 100vh;
  color: $color-light;
  overflow: hidden;
  width: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  z-index: 3;
}

.half {
  padding-right: $small-margin;

  @include mq(sm) {
    width: 100%;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;

    @include mq(sm) {
      font-size: 3.8rem;
    }

    span {
      position: relative;
      // display: inline-block;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0px;
        left: 0;
        width: 105%;
        height: 2px;
        background: #fff;
        content: "";
        transform: scaleX(1);
        transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
        transform-origin: 0 0;

        @include mq(sm) {
          bottom: 0px;
        }
      }

      :global(.no-touchevents) &:hover {
        &::after {
          transform: scaleX(0);
          height: 4px;
        }
      }
    }
  }

  strong {
    display: block;
    margin-top: $small-margin;
    font-size: font-size(40);
    line-height: 1.5;
  }
}

.projects {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  > div {
    width: 100%;
    flex: 0 0 auto;
  }

  > div:nth-child(even) {
    margin-top: 100px;
  }
}

.funnies {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 120px;

  > div {
    width: 100%;
    flex: 0 0 auto;

    @include mq(sm) {
      width: 50%;
    }
  }
}

.intro {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 90vh;
  flex: 1 1 auto;

  strong {
    color: $color-main;
    opacity: 0.7;
    font-size: font-size(32);
    font-weight: font-weight(regular);
  }
}

.hover-gif {
  position: absolute;
  top: 0;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  z-index: -1;
  pointer-events: none;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 85%;
    margin: auto;
    opacity: 0;
    visibility: hidden;
  }
}
</style>

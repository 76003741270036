export function getDevice() {
  let device = window.getComputedStyle(document.body, '::after').getPropertyValue('content')
  device = device.replace(/('|")/g, '')

  return device
}

export function isMobile() {
  return !(getDevice() !== 'sm' && getDevice() !== 'xs')
}

export function addClass(el, className) {
  el.classList.add(className)
}

export const isTouch = () => (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))

export const qs = (selector, ctx = document) => ctx.querySelector(selector)

export const qsa = (selector, ctx = document) => Array.from(ctx.querySelectorAll(selector))

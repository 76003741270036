<template>
  <div :class="[$style.container, $style.fun]">
    <Hero :visible="true" ref="hero" :id="project" :data="data"/>
    <div :class="$style.content" v-if="data" style="background-color: #111;">
      <Column :class="$style.intro">
        <span data-animation="text">{{data.subtitle}}</span>
        <h1 data-animation="text">{{data.title}}</h1>
      </Column>
      <div :class="$style.wrapper">
        <div
          :class="[
            $style.row,
            slice.primary.line === 'true' ? $style.bordered : null
          ]"
          :style="{ backgroundColor: slice.primary.background1 }"
          v-for="(slice, index) in data.body"
          :key="index">
          <component
            :is="slice.slice_type"
            :slice="slice"
          />
        </div>
      </div>
      <Next :project="next" type="fun"/>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

import Engine from '@/scripts/webgl'
import Hero from '@/components/Hero.vue'
import Next from '@/components/Next.vue'
import Column from '@/components/Column.vue'
import TextOnly from '@/components/project/TextOnly.vue'
import BigImage from '@/components/project/BigImage.vue'
import Sticky from '@/components/project/Sticky.vue'
import Slideshow from '@/components/project/Slideshow.vue'
import Gallery from '@/components/project/Gallery.vue'
import Flag from '@/components/project/Flag.vue'
import UxPersona from '@/components/project/UxPersona.vue'
import VideoPlayer from '@/components/project/VideoPlayer.vue'

export default {
  name: 'fun',
  props: {
    project: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      data: {}
    }
  },

  components: {
    Hero,
    Column,
    flag: Flag,
    slideshow: Slideshow,
    'video-player': VideoPlayer,
    sticky: Sticky,
    gallery: Gallery,
    big_image: BigImage,
    text_only: TextOnly,
    ux_persona: UxPersona,
    Next
  },

  created() {
    this.$smooth.initScrollbar().then(() => {
      this.$smooth.listenScroll(this)
    })

    this.$nextTick(() => {
      const images = Array.from(document.querySelectorAll('[data-cover]'))

      if (!Engine.textures.length) {
        Engine.createTextures(images)
      }
    })

    this.data = this.$store.getters.getFun(this.project)
    this.next = this.$store.getters.getNextFun(this.project)
  },

  methods: {
    customScrollbar(status) {
      const hero = document.querySelector('[data-hero-image]')

      if (this.$smooth.scrollbar.isVisible(hero)) {
        gsap.set(hero, {
          y: (status.offset.y / window.innerHeight) * 200
        })
      }

      this.checkCaption(status)
    }
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";

.container {
  color: $color-light;

  p {
    opacity: 0.6;
    color: $color-light;
  }

  h3 {
    font-weight: font-weight(demi);
  }

  .intro {

    span {
      font-weight: font-weight(demi);
      color: $color-main;
      letter-spacing:5px;
      text-transform:uppercase;
    }

    h1 {
      font-size: 40px;
      font-weight: font-weight(demi);
      border-bottom: 3px solid $color-main;
      padding-bottom: $giga-margin-mobile;

      @include mq(sm) {
        font-size: 72px;
        padding-bottom: $giga-margin / 2;
      }
    }
  }
}

.content {
  padding-top: 50px;

  @include mq(sm) {
    padding-top: 100px;
  }
}

.fun {
  background: $color-dark;
}

.wrapper {
  > div:first-of-type {
    padding-top: $big-margin-mobile;

    @include mq(sm) {
      padding-top: $giga-margin / 2;
    }
  }
}

.row {
  position: relative;
  padding: $big-margin-mobile 0;

  @include mq(sm) {
    padding: $giga-margin 0;

    &.bordered {
      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        max-width: $container-width;
        border-bottom: 1px solid #fff;
      }
    }
  }
}
</style>

<template>
<transition appear @enter="onEnter">
  <header :class="{
      [$style.header]: true,
      [$style.dark]: this.$route.name === 'project' && !isThemeLight
    }">
    <div>
      <div
        :class="{
          [$style.isMenuOpen]: isMenuOpen,
          [$style.logoContainer]: true
        }"
      >
        <router-link :to="{ path: '/' }">
          <svg @mouseenter.prevent="hover" @mouseleave.prevent="hover" data-logo :class="$style.logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 16">
            <path d="M11.2 4.2L8.7 5c-.2-.8-.8-2-2.6-2-1.3 0-2.2.9-2.2 1.8 0 .8.5 1.4 1.5 1.6l2 .4c2.6.5 4 2.2 4 4.2 0 2.2-1.8 4.4-5.1 4.4C2.5 15.4.9 13 .7 11l2.6-.7c.1 1.4 1.1 2.7 3.1 2.7 1.5 0 2.3-.7 2.3-1.7 0-.8-.6-1.5-1.7-1.7l-2-.4C2.6 8.6 1.1 7.1 1.1 5 1.1 2.5 3.4.6 6 .6c3.5 0 4.8 2 5.2 3.6z"/>
            <path d="M19.1.8h-5V15H19c3.6 0 6.8-2.4 6.8-7.1 0-4.6-3.1-7.1-6.7-7.1zM19 13.3h-2.9V2.6H19c2.6 0 4.8 1.8 4.8 5.4 0 3.5-2.2 5.3-4.8 5.3z"/>
            <path d="M35.4 9.2c1.9-.5 3-2 3-4 0-2.5-1.8-4.4-4.5-4.4h-5.5V15h2.8V9.5h1.4l2.8 5.5h3.1l-3.1-5.8zm-2-2.1h-2.2V3.2h2.2c1.4 0 2.2.8 2.2 2 0 1.1-.8 1.9-2.2 1.9z"/>
          </svg>
        </router-link>
        <span :class="$style.caption">
          SDR – Product Designer
        </span>
      </div>
    </div>
  </header>
</transition>
</template>

<script>
import gsap from 'gsap'

export default {
  data() {
    return {
      isMenuOpen: false
    }
  },

  computed: {
    isThemeLight() {
      return this.$store.state.isHeaderLight
    }
  },

  methods: {
    // toggleMenu() {
    //   this.isMenuOpen = !this.isMenuOpen
    //   this.$emit('trigger-menu', this.isMenuOpen)
    // },

    onEnter(el, done) {
      gsap.from(el, {
        duration: 1,
        autoAlpha: 0,
        delay: 3,
        onComplete: () => {
          done()
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
@import "~sass-mq/mq";
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: $container-width;
  color: white;
  z-index: z-index(header);
  overflow: hidden;
  margin: $standard-margin $small-margin;

  @include mq(sm) {
    margin: $standard-margin;
  }
}

.logo {
  width: 40px;
  fill: white;
  transition: all 0.5s;
  margin-right: 20px;
}

.caption {
  visibility: hidden;
  opacity: 0;
  transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: normal;
  font-size: 10px;

  @include mq(sm) {
    font-size: 16px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);

  &.isMenuOpen {
    transform: translate3d(-40px, 0, 0);

    .caption {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dark {
  .logo {
    fill: $color-white;
  }
}

[data-green] {
  .logo {
    fill: $color-main;
  }
}

.hamburger {
  position: relative;
  transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);

  span:first-of-type {
    position: absolute;
    right: 100%;
    backgroundColor: $color-main;
    top: 0;
    padding-top: 2px;
    bottom: 3px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  }


  &.dark {
    span {
      color: $color-dark;
    }

    &::after {
      background: $color-dark;
    }
  }

  &[data-green] {
    span {
      color: $color-main;
    }

    &::after {
      background: $color-main;
    }
  }

  &.open {
    span {
      color: $color-light;
    }

    transform: translateX(100%);

    span {
      opacity: 1;
      visibility: visible;
    }

    &::after {
      background: $color-light;
    }
  }
}
</style>

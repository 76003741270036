<template>
<article  :class="$style.container">
  <Column>
    <div :class="$style.content">
      <h3 data-animation="text">{{ slice.primary.title1 }}</h3>
      <prismic-rich-text data-animation="fade-up" :field="slice.primary.text"/>
    </div>
    <div :class="$style.grid">
      <div :class="$style.image" v-for="(item, index) in slice.items" :key="index">
        <prismic-image data-animation="fade-up" :field="item.image"/>
      </div>
    </div>
  </Column>
</article>
</template>

<script>
import Column from '@/components/Column.vue'

export default {
  props: ['slice'],
  components: {
    Column
  }
}
</script>

<style module lang="scss">
@import "~sass-mq/mq";

.content {
  min-height: 300px;
  padding-right: 50px;
  font-size: font-size(l);

  @include mq(sm) {
    width: 50%;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;

  > div {
    width: 50%;
    flex: 0 0 auto;

    &:nth-of-type(even) {
      transform: translateY(+20%);
    }

    @include mq(sm) {
      &:nth-of-type(even) {
        transform: translateY(-40%);
      }
    }

  }
}

.image {
  padding: 10px;
  width: 100%;

  img {
    width: 100%;
    margin:40px 0;
  }
}
</style>

import gsap, { Expo } from 'gsap'

const BlackEnter = (page) => {
  const tl = gsap.timeline({ paused: true })

  tl
    .set(page, {
      clearProps: 'all'
    })
    .to('#black-layer', {
      duration: 1.3,
      ease: Expo.easeInOut,
      yPercent: -100
    }, 'start')
    .set(document.body, { clearProps: 'all' })

  return tl
}

export default BlackEnter
